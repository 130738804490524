import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';

const TermsAndConditionsPage = () => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.defer = true;
      script.text = '(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);';
      ref.current.appendChild(script);
    }
  }, [ref]);

  return (
    <div ref={ref}>
      <a
        href="https://www.iubenda.com/terms-and-conditions/32714190"
        className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed"
        title="Terms and Conditions"
      >
        {' '}
      </a>
      <Helmet>
        <script type="text/javascript">
          {'(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);'}
        </script>
      </Helmet>
    </div>
  );
};

export default TermsAndConditionsPage;
